import * as React from "react";
import { createRoot } from "react-dom/client";
import { observer } from "mobx-react";
// import { loadFull } from "tsparticles";
// import Particles from "react-particles";

import { useStore } from "./stores/main";
import { Front } from "./views/front/main";
import { Navigation } from "./views/navigation/main";
import { Content } from "./views/content/main";
import { Loader } from "./views/loader";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "./style.scss";

const container = document.getElementById('react-container');
if (!container) throw new Error('Failed to find the root container');
const root = createRoot(container);

// const Background = () => {
//     const particlesInit = React.useCallback(async engine => {
//         console.log(engine);
//         // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
//         // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
//         // starting from v2 you can add only the features you need reducing the bundle size
//         await loadFull(engine);
//     }, []);
    
//     const particlesLoaded = React.useCallback(async container => {
//         await console.log(container);
//     }, []);

//     return (
//         <Particles id="tsparticles" url="static/assets/particles.json" init={particlesInit} loaded={particlesLoaded} />
//     );
// }

const App = observer(() => {
    if (useStore().appState == 'loading') return <Loader />;
    return <React.Fragment>
        <Navigation />
        <Main />
    </React.Fragment>
});

const Main = observer(() => {
    const store = useStore();
    switch (store.appState) {
        case 'front':
            return <Front />
        case 'navigation':
            return <Content />
        case 'loading':
            return (<div />);
    }
});


root.render((
    <App />
));
