import { action, computed, makeObservable, observable } from "mobx";

export type AssetsState = 'inital' | 'loading' | 'loaded'

export class AssetsProvider {

    state: AssetsState = 'inital';
    loadingOperationsCount: number;
    loadingOperationsCompleted: number = 0;

    get preloadProgress(): number {
        return Math.round(this.loadingOperationsCompleted / this.loadingOperationsCount * 100);
    }

    // - Constructors

    constructor() {
        this.loadingOperationsCount = 2 * this.assetsMap.size;

        makeObservable(this, {
            state: observable,
            loadingOperationsCount: observable,
            loadingOperationsCompleted: observable,
            preloadProgress: computed,
            onLoading: action,
            onLoaded: action,
            onOperationComplete: action,
        });

        this.initDB();
        this.startPreload()
            .then(() => {
                console.debug("preload completed");
                if (this.loadingOperationsCompleted == this.loadingOperationsCount) this.onLoaded();
            });
    }

    // - Public methods

    getFolderAsset(id: string): string {
        return this.resolvedUrlsMap[id];
    }

    getProjectAsset(id: string): string {
        return this.resolvedUrlsMap[id];
    }

    // Actions

    onLoading() { this.state = 'loading'; }
    onLoaded() { this.state = 'loaded'; }

    onClear() { this.loadingOperationsCompleted = 0; }
    onOperationComplete() { this.loadingOperationsCompleted += 1; }

    // - Private properties

    private getStore: (txMode: IDBTransactionMode | undefined, callback: (store: IDBObjectStore) => any) => Promise<any>;

    // -

    private resolvedUrlsMap = new Map<string, string>([]);

    private assetsMap = new Map<string, URL>([
        // Folder assets
        // 3D
        ['yota_ring', require("../assets/3d_models/01.jpg")],
        ['pink_face', require("../assets/3d_models/02.jpg")],
        ['drinkins', require("../assets/3d_models/drinkins.jpg")],
        ['ar_achivements', require("../assets/3d_models/04.jpg")],
        ['plane_seats', require("../assets/3d_models/05.jpg")],
        ['reciever', require("../assets/3d_models/06.jpg")],
        ['wood_lamp', require("../assets/3d_models/07.jpg")],
        ['ar_masks', require("../assets/3d_models/08.jpg")],
        // Motion
        ['sleepy_animations', require("../assets/motion/sleepy.jpg")],
        ['aita_marketing', require("../assets/motion/aita_marketing.jpg")],
        ['aita_stickers', require("../assets/motion/aita_stickers.jpg")],
        ['aita_placeholders', require("../assets/motion/aita_placeholders.jpg")],
        ['life_animations', require("../assets/motion/life.jpg")],
        ['yota_animations', require("../assets/motion/yota.jpg")],
        // Illustrations
        ['robots', require("../assets/illustrations/robotos.jpg")],
        ['telegram_stickers', require("../assets/illustrations/stickers.jpg")],
        ['life_game_backgrounds', require("../assets/illustrations/vector.jpg")],
        ['aita_banners', require("../assets/illustrations/aita_banners.jpg")],
        ['raster_art', require("../assets/illustrations/tatoo.jpg")],
        ['aita_achievements', require("../assets/illustrations/achievements.jpg")],
        //
        // Project assets
        //
        ['pink_face_happy_1', require("../assets/3d_models/pink_face/happy_1.png")],
        ['pink_face_neutral_1', require("../assets/3d_models/pink_face/neutral_1.png")],
        ['pink_face_sad_1', require("../assets/3d_models/pink_face/sad_1.png")],
        ['pink_face_happy_2', require("../assets/3d_models/pink_face/happy_2.png")],
        ['pink_face_neutral_2', require("../assets/3d_models/pink_face/neutral_2.png")],
        ['pink_face_sad_2', require("../assets/3d_models/pink_face/sad_2.png")], 
        // -
        ['drinkins_1', require("../assets/3d_models/drinkins/drinkins_1.png")],
        ['drinkins_2', require("../assets/3d_models/drinkins/drinkins_2.png")],
        ['drinkins_3', require("../assets/3d_models/drinkins/drinkins_3.png")],
        ['drinkins_4', require("../assets/3d_models/drinkins/drinkins_4.png")],
        ['drinkins_5', require("../assets/3d_models/drinkins/drinkins_5.png")],
        ['drinkins_6', require("../assets/3d_models/drinkins/drinkins_6.png")],
        // -
        ['ar_achivements_1', require("../assets/3d_models/ar_achivements/1.png")],
        ['ar_achivements_2', require("../assets/3d_models/ar_achivements/2.png")],
        ['ar_achivements_3', require("../assets/3d_models/ar_achivements/3.png")],
        ['ar_achivements_4', require("../assets/3d_models/ar_achivements/4.png")],
        // -
        ['plane_seats_1', require("../assets/3d_models/plane_seats/1.png")],
        ['plane_seats_2', require("../assets/3d_models/plane_seats/2.png")],
        ['plane_seats_3', require("../assets/3d_models/plane_seats/3.png")],
        // -
        ['wood_lamp_1', require("../assets/3d_models/wood_lamp/1.png")],
        ['wood_lamp_2', require("../assets/3d_models/wood_lamp/2.mp4")],
        ['wood_lamp_3', require("../assets/3d_models/wood_lamp/3.png")],
        // -
        ['yota_ring_1', require("../assets/3d_models/yota_ring/1.png")],
        ['yota_ring_2', require("../assets/3d_models/yota_ring/2.png")],
        ['yota_ring_3', require("../assets/3d_models/yota_ring/3.png")],
        ['yota_ring_4', require("../assets/3d_models/yota_ring/4.png")],
        // -
        ['reciever_1', require("../assets/3d_models/reciever/1.png")],
        ['reciever_2', require("../assets/3d_models/reciever/2.png")],
        ['reciever_3', require("../assets/3d_models/reciever/3.png")],
        // -
        ['ar_masks_1', require("../assets/3d_models/ar_masks/1.png")],
        ['ar_masks_2', require("../assets/3d_models/ar_masks/2.png")],
        ['ar_masks_3', require("../assets/3d_models/ar_masks/3.png")],
        // -
        ['sleepy_animations_1', require("../assets/motion/sleepy/1.lottie")],
        ['sleepy_animations_1_fill', require("../assets/motion/sleepy/1_fill.png")],
        ['sleepy_animations_2', require("../assets/motion/sleepy/2.lottie")],
        ['sleepy_animations_3', require("../assets/motion/sleepy/3_fill.mp4")],
        // -
        ['aita_marketing_1', require("../assets/motion/aita_marketing/1.mp4")],
        ['aita_marketing_2', require("../assets/motion/aita_marketing/2.mp4")],
        // -
        ['aita_stickers_evil', require("../assets/motion/aita_stickers/evil.lottie")],
        ['aita_stickers_travelator', require("../assets/motion/aita_stickers/travelator.lottie")],
        ['aita_stickers_beach', require("../assets/motion/aita_stickers/beach.lottie")],
        ['aita_stickers_voting', require("../assets/motion/aita_stickers/voting.lottie")],
        ['aita_stickers_packing', require("../assets/motion/aita_stickers/packing.lottie")],
        ['aita_stickers_drinkins', require("../assets/motion/aita_stickers/drinkins.lottie")],
        ['aita_stickers_passport', require("../assets/motion/aita_stickers/passport.lottie")],
        // -
        ['aita_placeholders_1', require("../assets/motion/aita_placeholders/1.lottie")],
        ['aita_placeholders_1_fill', require("../assets/motion/aita_placeholders/1_fill.png")],
        ['aita_placeholders_2', require("../assets/motion/aita_placeholders/2.lottie")],
        ['aita_placeholders_3', require("../assets/motion/aita_placeholders/3.lottie")],
        ['aita_placeholders_4', require("../assets/motion/aita_placeholders/4.lottie")],
        ['aita_placeholders_5', require("../assets/motion/aita_placeholders/5.lottie")],
        // -
        ['yota_animations_1', require("../assets/motion/yota/1.png")],
        ['yota_animations_2', require("../assets/motion/yota/2.mp4")],
        // -
        ['life_animations_1', require("../assets/motion/life/1.png")],
        ['life_animations_2', require("../assets/motion/life/2.mp4")],
        // -
        ['robots_1', require("../assets/illustrations/robots/1.png")],
        ['robots_2', require("../assets/illustrations/robots/2.png")],
        ['robots_3', require("../assets/illustrations/robots/3.png")],
        // -
        ['telegram_stickers_1', require("../assets/illustrations/stickers/1.png")],
        ['telegram_stickers_2', require("../assets/illustrations/stickers/2.png")],
        ['telegram_stickers_3', require("../assets/illustrations/stickers/3.png")],
        // -
        ['life_game_backgrounds_1', require("../assets/illustrations/life_games/1.png")],
        ['life_game_backgrounds_2', require("../assets/illustrations/life_games/2.png")],
        ['life_game_backgrounds_3', require("../assets/illustrations/life_games/3.png")],
        // -
        ['aita_banners_1', require("../assets/illustrations/aita_banners/1.png")],
        ['aita_banners_2', require("../assets/illustrations/aita_banners/2.png")],
        ['aita_banners_3', require("../assets/illustrations/aita_banners/3.png")],
        // -
        ['raster_art_1', require("../assets/illustrations/raster_art/1.png")],
        ['raster_art_2', require("../assets/illustrations/raster_art/2.png")],
        ['raster_art_3', require("../assets/illustrations/raster_art/3.png")],
        // -
        ['achievements_1', require("../assets/illustrations/achievements/1.png")],
        ['achievements_2', require("../assets/illustrations/achievements/2.png")],
        ['achievements_3', require("../assets/illustrations/achievements/3.png")],
    ]);

    // - Private methods

    private initDB() {
        const request = indexedDB.open('allangry');
        request.onupgradeneeded = () => request.result.createObjectStore('assets');
        const openPromise = promisifyRequest(request);

        this.getStore = (txMode: IDBTransactionMode | undefined, callback: (store: IDBObjectStore) => any) =>
            openPromise.then((db) => callback(db.transaction('assets', txMode).objectStore('assets')));
    }

    private async startPreload() {
        console.debug("preload started");
        
        this.onClear();
        this.resolvedUrlsMap.clear();
        var needLoading = false;

        type AssetResponse = {key: string, value: URL, response: Response};
        var requests = new Array<Promise<AssetResponse>>();

        for (let [key, value] of this.assetsMap) {
            if (!(await this.hasKey(key)) || (await this.isAssetChanged(key, value))) {
                console.debug(`asset '${key}' not cached, loading: ${value}`);

                if (!needLoading) {
                    needLoading = true;
                    this.onLoading();
                }
                
                requests.push(
                    fetch(value)
                        .then((response) => { 
                            this.onOperationComplete();
                            return {key: key, value: value, response: response} 
                        })
                );
            } else {
                this.onOperationComplete();
            }
        }

        let responses = await Promise.all(requests);

        for (let item of responses) {
            if (!item.response.ok) {
                console.debug(`request for '${item.key}' failed: ${item.response.statusText}`);
                return;
            }
            const data = await item.response.blob();
            await this.setValue<Blob>(item.key, data);
            await this.setHashValue(item.key, item.value);
            console.debug(`request for '${item.key}' successfully finished`);
        };

        for (let key of this.assetsMap.keys()) {
            if (!(await this.hasKey(key))) {
                console.debug(`asset '${key}' had failed to cache`)
                continue;
            }
            const blob = await this.getValue<Blob>(key);
            this.resolvedUrlsMap[key] = URL.createObjectURL(blob);
            this.onOperationComplete();
            console.debug(`preloaded data url for '${key}' is: ${this.resolvedUrlsMap[key]}`);
        }
    }

    private async hasKey(key: string): Promise<boolean> {
        const result = this.getStore('readonly', (store) => promisifyRequest(store.get(key)));
        return result.then(
            (value) => value instanceof Blob, 
            () => false
        );
    }

    private async getValue<T>(key: string): Promise<T> {
        const value = await this.getStore('readonly', (store) => promisifyRequest(store.get(key)));
        return <T>value;
    }

    private async setValue<T>(key: string, value: T): Promise<void> {
        const result = await this.getStore('readwrite', (store) => {
            store.put(value, key);
            return promisifyTransaction(store.transaction);
        })
    }

    // -

    private async isAssetChanged(key: string, value: URL): Promise<boolean> {
        const hashKey = this.toHashKey(key);
        const cachedHash = await this.getValue<string>(hashKey);
        if (!cachedHash) return true;
        return cachedHash != value.toString();
    }

    private async setHashValue(key: string, value: URL): Promise<void> {
        await this.setValue<string>(this.toHashKey(key), value.toString());
    }

    private toHashKey(key: string): string {
        return key + "_hash";
    }

}

function promisifyRequest<T>(request: IDBRequest<T>): Promise<T> {
    return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

function promisifyTransaction(request: IDBTransaction): Promise<void> {
    return new Promise((resolve, reject) => {
        request.oncomplete = () => resolve();
        request.onabort = request.onerror = () => reject(request.error);
    });
}