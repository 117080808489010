import * as React from "react";
import TweenOne from "rc-tween-one";

type FolderItemState = {
    image: any
    title: string
    isHovering: boolean
    onClick: () => void
    onHoverChange: (a: boolean) => void
};

export const FolderItem = ({image, title, isHovering, onClick, onHoverChange}: FolderItemState) => {
    return (
        <div 
            className="content-item"
            onMouseOver={(e) => onHoverChange(true) }
            onMouseOut={(e) => onHoverChange(false) }
            onClick={onClick}
        >
            <TweenOne 
                className="relative-fill-parent" 
                animation={{ scale: 1.1 }} 
                reverse={!isHovering}
            >
                <img src={image} alt="item"/>
            </TweenOne>
            <TweenOne 
                className="content-item-hover" 
                animation={{ opacity: 1.0 }} 
                reverse={!isHovering} 
            />
            <TweenOne 
                className="content-item-title"
                animation={{ opacity: 1.0 }} 
                reverse={!isHovering}
            >
                <p>{title}</p>
            </TweenOne>
        </div>
    );
};

export const TransitionFolderItem = ({image, }: FolderItemState) => (
    <div className="content-item">
        <div className="relative-fill-parent">
            <img src={image} alt="item"/>
        </div>
    </div>
);