import * as React from 'react';
import { observer } from 'mobx-react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { BaseComponent } from '../stores/main';

type Scene = {
    container: Element;
    renderer: THREE.WebGLRenderer;
    composer: EffectComposer;
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
}

export const CatScene = observer(
    class CatScene extends BaseComponent {

        scene: Scene | null = null;
        model: THREE.Group | null = null;
        modelOriginalScale: THREE.Vector3 = new THREE.Vector3();

        componentDidMount() {
            this.setupScene();
            this.loadModel();
            this.resizeCanvasToDisplaySize();
            this.startAnimationLoop();
            document.addEventListener('mousemove', this.onMouseMove, false);
            window.addEventListener('resize', this.resizeCanvasToDisplaySize);
        }

        componentWillUnmount() {
            this.scene = null;
            this.model = null;
            document.removeEventListener('mousemove', this.onMouseMove, false);
            window.removeEventListener('resize', this.resizeCanvasToDisplaySize);
        }

        render() {
            return <canvas id="scene"></canvas>
        }

        // -

        setupScene = () => {
            const container = document.querySelector("#scene-container");
            const canvas = document.querySelector("#scene");
            if (!container) throw new Error('Failed to find the scene container');
            if (!canvas) throw new Error('Failed to find the scene canvas');
            
            const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvas });

            const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.1, 1000 );
            camera.position.z = 10;

            const scene = new THREE.Scene();

            const composer = new EffectComposer(renderer);

            const renderPass = new RenderPass(scene, camera);
            composer.addPass(renderPass);

            const opacityPass = new ShaderPass(this.opacityShader(0.8));
            composer.addPass(opacityPass);

            this.scene = {container, renderer, composer, camera, scene};
        };

        opacityShader = (opacity: number) => {
            return new THREE.ShaderMaterial({
                uniforms: {
                    tDiffuse: { value: null },
                    modelOpacity: { value: opacity }
                },
                vertexShader: `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
                    }
                `,
                fragmentShader: `
                    varying vec2 vUv;
                    uniform sampler2D tDiffuse;
                    uniform float modelOpacity;
                    void main() {
                        vec4 previousPassColor = texture2D(tDiffuse, vUv);
                        gl_FragColor = vec4(previousPassColor.rgb, previousPassColor.a * modelOpacity);
                    }
                `,
            });
          };

        loadModel = () => {
            const loader = new GLTFLoader();
            const sceenScene = this.scene?.scene;
            const ref = this;

            const modelSize = 7.5;

            loader.load(
                'static/assets/cat_lowpoly.glb', 
                function ( gltf ) { 
                    var material = new THREE.MeshNormalMaterial();
                    var model = gltf.scene;
                    ref.model = model;

                    model.traverse((o) => { 
                        if (o instanceof THREE.Mesh) { o.material = material; }
                    });
                     
                    normalize(model);

                    model.scale.multiplyScalar(modelSize);
                    ref.modelOriginalScale.copy(model.scale);
                    ref.updateModelScale();

                    sceenScene?.add(model);
                }, 
                undefined, 
                function ( error ) { console.error( error ); } 
            );
        }

        startAnimationLoop = () => {
            if (!this.scene) { return; }
            this.scene.composer.render();
            window.requestAnimationFrame(this.startAnimationLoop);
        };

        // -

        onMouseMove = (event) => {
            if (!this.scene) { return; }
            var position = projectMouse(this.scene.camera, event.clientX, event.clientY, 5);
            position.z = 20;
            position.y -= 3;
            this.model?.lookAt(position);
        };

        resizeCanvasToDisplaySize = () => {
            if (!this.scene) { return; }

            const canvas = this.scene.renderer.domElement;
            const pixelRatio = window.devicePixelRatio;
        
            const width = this.scene.container.clientWidth / pixelRatio;
            const height = this.scene.container.clientHeight / pixelRatio;
          
            this.scene.renderer.setPixelRatio(window.devicePixelRatio);

            if (canvas.width !== width || canvas.height !== height) {
                // you must pass false here or three.js sadly fights the browser
                this.scene.renderer.setSize(width, height, false);
                this.scene.camera.aspect = width / height;
                this.scene.camera.updateProjectionMatrix();
                   
                if (width > height) {
                    const ofsettedWidth = 1.25 * width;
                    const offset = ofsettedWidth - width;
                    this.scene.camera.setViewOffset(ofsettedWidth, height, offset, 0.0, width, height);
                } else {
                    const ofsettedHeight = 1.25 * height;
                    const offset = ofsettedHeight - height;
                    this.scene.camera.setViewOffset(width, ofsettedHeight, 0.0, offset, width, height);
                }

                this.scene.composer.setSize(width * pixelRatio, height * pixelRatio);
                this.updateModelScale();
            }
        };

        updateModelScale = () => {
            if (!this.scene) { return; }
            if (!this.model) { return; }

            const pixelRatio = window.devicePixelRatio;
            const width = this.scene.container.clientWidth / pixelRatio;
            const height = this.scene.container.clientHeight / pixelRatio;

            if (width < height) {
                const portraitModelScale = 0.7; // (?)
                this.model.scale.setX(this.modelOriginalScale.x * portraitModelScale);
                this.model.scale.setY(this.modelOriginalScale.y * portraitModelScale);
                this.model.scale.setZ(this.modelOriginalScale.z * portraitModelScale);
            } else {
                this.model.scale.copy(this.modelOriginalScale);
            }
        }
    }
);

// - Helpers

var mouseProjectionVector = new THREE.Vector3(); // create once and reuse
var mouseProjectionPosition = new THREE.Vector3(); // create once and reuse

function projectMouse(camera: THREE.PerspectiveCamera, x: number, y: number, targetZ: number) {
    mouseProjectionVector.set(
        (x / window.innerWidth) * 2 - 1,
        -(y / window.innerHeight) * 2 + 1,
        0.5
    );
    
    mouseProjectionVector.unproject(camera);
    mouseProjectionVector.sub(camera.position).normalize();
    
    const distance = (targetZ - camera.position.z) / mouseProjectionVector.z;

    mouseProjectionPosition
        .copy(camera.position)
        .add(mouseProjectionVector.multiplyScalar(distance));
        
    return mouseProjectionPosition;
}

function normalize(model: THREE.Group) {
    var bbox = new THREE.Box3().setFromObject(model);
    var cent = bbox.getCenter(new THREE.Vector3());
    var size = bbox.getSize(new THREE.Vector3());
    
    var maxAxis = Math.max(size.x, size.y, size.z);
    model.scale.multiplyScalar(1.0 / maxAxis);
    bbox.setFromObject(model);
    bbox.getCenter(cent);
    bbox.getSize(size);
    //Reposition to 0,halfY,0
    model.position.copy(cent).multiplyScalar(-1);
    model.position.y-= (size.y * 0.5);
}
