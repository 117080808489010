import { action, makeObservable, observable } from "mobx";

export class Size {
    width: number
    height: number
    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
};

export class UIStore {
    screenSize = new Size(window.innerWidth, window.innerHeight);
    backEventToggle: boolean = false;

    constructor() {
        makeObservable<UIStore, "setScreenSize" | "pushEvent">(this, {
            screenSize: observable,
            backEventToggle: observable,
            markBackEventAsHandled: action,
            setScreenSize: action,
            pushEvent: action
        });

        window.addEventListener('resize', () => {
            this.setScreenSize(new Size(window.innerWidth, window.innerHeight));
        }, false);

        history.pushState(0, "", null);
        history.pushState(1, "", null);

        window.addEventListener('popstate', (event) => {
            console.log('popstate: ', event);
            if (event.state as number == 0) {
                this.pushEvent();
                history.forward();
            }
        }, false);
    }

    get isCompact(): boolean {
        return this.screenSize.width < this.screenSize.height;
    }

    markBackEventAsHandled() {
        this.backEventToggle = false;
    }

    private setScreenSize(size: Size) {
        this.screenSize = size;
    }

    private pushEvent() {
        if (this.backEventToggle) { return; }
        this.backEventToggle = true;
    }

};
