import { Component, createContext, useContext } from "react";
import { autorun, makeObservable, observable } from "mobx";
import { ButtonKind, FrontStore } from "./frontStore";
import { UIStore } from "./uiStore";
import { NavigationStore } from "./navigationStore";
import { ContentItemKind, ContentStore } from "./contentStore";
import { AssetsProvider } from "./assetsProvider";
import { LoaderStore } from "./loaderStore";

// - Main store

type AppState = 'loading' | 'front' | 'navigation'

export class MainStore {
    
    // - Component stores

    loader = new LoaderStore();
    front = new FrontStore();
    navigation = new NavigationStore();
    content = new ContentStore();
    ui = new UIStore();

    // - Other

    assetsProvider = new AssetsProvider();

    // -

    constructor() {
        makeObservable(this, {
            loader: observable,
            front: observable,
            navigation: observable,
            content: observable,
            ui: observable,
            assetsProvider: observable,
        });

        if (this.ui.isCompact) {
            this.front.lockButtonsHover();
        }

        autorun(() => { 
            switch (this.appState) {
            case 'front': this.front.unlockButtonsHover(); break;
            case 'navigation': this.front.lockButtonsHover(); break;
            }
        });

        autorun(() => {
            if (this.navigation.isAppeared && this.pendingScreen) {
                this.navigation.routeForward(this.pendingScreen);
                this.content.showItem(this.pendingScreen);
                this.pendingScreen = null;
            }
        });

        autorun(() => {
            if (this.navigation.isGoingUpToRoot) {
                this.content.hideContent();
            }
        });

        autorun(() => {
            if (this.assetsProvider.state == 'loaded') { 
                this.loader.handleAssetsLoaded();
            }
        });

        autorun(() => {
            if (this.ui.isCompact) { 
                this.front.lockButtonsHover();
            } else {
                this.front.unlockButtonsHover();
            }
        });

        autorun(() => {
            if (!this.ui.backEventToggle) { return; }
            this.handleBrowserBackButton();
        });
    }

    // -

    get appState(): AppState {
        if (this.assetsProvider.state != 'loaded' || this.loader.isPlaying) return 'loading';
        if (this.navigation.isActive) return 'navigation';
        return 'front';
    }

    // -

    handleFrontButtonTap(kind: ButtonKind) {
        if (this.navigation.isAnimating) { return; }
        if (this.pendingScreen) { throw new Error("Can't hold multiple pending screens") }
        this.navigation.routeToRoot();
        this.pendingScreen = {type: 'folder', kind: kind };
    }

    handleNavigationAnimationEnd() {
        if (!this.pendingScreen) { throw new Error("Pending screen is not saved") }
        this.navigation.handleAppearComplete();
    }

    handleNavigationLogoTap() {
        if (this.navigation.isAnimating) { return; }
        this.navigation.routeToInactive();
    }

    handleNavigationPanelTap(depth: number) {
        this.routeToDepth(depth);
    }

    handleBrowserBackButton() {
        this.ui.markBackEventAsHandled();

        if (!this.navigation.isActive) { return; }
        if (this.navigation.isAnimating) { return; }

        if (this.navigation.depth > 1) {
            this.routeToDepth(this.navigation.depth - 1);
        } else {
            this.navigation.routeToInactive();
        }
    }

    private pendingScreen: ContentItemKind | null;

    private routeToDepth(depth: number) {
        if (this.navigation.depth <= depth) { throw new Error("Can't route deeper") }
        this.navigation.routeBackToDepth(depth);

        const stack = this.navigation.screensStack;
        const topScreen = stack[stack.length - 1];
        if (!topScreen) { return; }
        this.content.showItem(topScreen);
    }

}

// - Store usage helpers

export const StoreContext = createContext(new MainStore());
export const useStore = () => useContext(StoreContext);

export class BaseComponent extends Component {
    static contextType = StoreContext;
    declare context: React.ContextType<typeof StoreContext>; 
    protected get store() { return this.context; }
};