import * as React from "react";
import { observer } from "mobx-react";
import { BaseComponent } from "../stores/main";
import ProgressBar from "@ramonak/react-progress-bar";

import '@dotlottie/player-component';

export const Loader = observer(
    class Loader extends BaseComponent {
        
        render() {
            return (
                <div id="loader-scene">
                    <dotlottie-player
                        autoplay
                        loop
                        src={require("../assets/loader_cats.lottie")}
                        speed={1}
                        background="transparent"
                        id="loader-lottie"
                    />
                    <ProgressBar 
                        completed={this.store.assetsProvider.preloadProgress}
                        customLabel={this.store.assetsProvider.preloadProgress + "%"}
                        transitionDuration="0.2"
                        className="loader-progressbar"
                        labelClassName="loader-label"
                        labelAlignment="outside"
                        bgColor="#96FE5B"
                        baseBgColor="#25274D"
                        height="1.2vh"
                    />
                </div>
            );
        }

        componentDidMount() {
            this.store.loader.handleLoaderStart();
        }

    }
);