import { action, autorun, computed, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import isEqual from "lodash.isequal";

// -

export type FolderKind = '3d_models' | 'motion' | 'graphics';

export type FolderItemKind = { type: 'folder', kind: FolderKind };
export type ProjectItemKind = { type: 'project', id: string, folder: FolderKind };
export type ContentItemKind = FolderItemKind | ProjectItemKind;

// -

type ProjectTitleDto = { id: string, title: string }

export class ProjectTitleItemModel {
    id: string;
    title: string;
    hoverState: boolean = false;
    onClick: () => ProjectItemKind;

    constructor(dto: ProjectTitleDto, onClick: () => ProjectItemKind) {
        this.id = dto.id;
        this.title = dto.title;
        this.onClick = onClick;
        makeAutoObservable(this);
    }

    changeHoverState(value: boolean) { this.hoverState = value; } 
}

// -

export type ProjectItem = { type: 'project', id: string };
export type FolderItem = { type: 'folder', kind: FolderItemKind, projects: ProjectTitleItemModel[] };
export type Item = FolderItem | ProjectItem;

// -

type Inactive = { type: 'inactive' };
type Idle = { type: 'idle', item: Item };
type StableState = Inactive | Idle;

type Appearing = { type: 'appearing', item: Item };
type Dismissing = { type: 'dismissing', item: Item };
type Animating = { type: 'animating', from: Item, to: Item };
type TransitionState = Appearing | Dismissing | Animating;

export type ContentState = StableState | TransitionState;

// -

export class ContentStore {
    currentState: ContentState = { type: 'inactive'};
    targetState: StableState = { type: 'inactive' };
    
    constructor() {
        makeObservable<ContentStore, 'reduceState'>(this, {
            currentState: observable,
            targetState: observable,
            isActive: computed,
            isAnimating: computed,
            isTransitionReversed: computed,
            showItem: action,
            hideContent: action,
            handleTransitionComplete: action,
            reduceState: action
        })

        autorun(() => {
            if (!isEqual(this.currentState, this.targetState)) {
                setTimeout(() => this.reduceState(), 100); 
            }
        });

        autorun(() => {
            if (this.currentState.type == 'animating') {
                const contentView = document.getElementById('content-grid');
                if (!contentView) return;
                contentView.scrollTop = 0;
            }
        });
    }

    // -

    get isActive(): boolean {
        return this.currentState.type != 'inactive'
    }

    get isAnimating(): boolean {
        return ['appearing', 'disappearing', 'animating'].includes(this.currentState.type);
    }

    get isTransitionReversed(): boolean {
        return ['dismissing', 'inactive'].includes(this.currentState.type);
    }

    // -

    showItem(kind: ContentItemKind) {
        if (this.isAnimating) return;
        const item = this.itemContent(kind);
        if (!item) { throw new Error("Failed to parse content item: " + kind); }
        this.targetState = { type: 'idle', item: item};
    }

    hideContent() {
        this.targetState = { type: 'inactive' };
    }

    handleTransitionComplete() {
        if (this.currentState.type == 'appearing') {
            this.currentState = { type: 'idle', item: this.currentState.item };
        } else if (this.currentState.type == 'dismissing') {
            this.currentState = { type: 'inactive' };
        } else if (this.currentState.type == 'animating') {
            this.currentState = { type: 'idle', item: this.currentState.to };
        } else {
            throw new Error("Incorrect state for transition completion");
        }
    }

    // -

    private content = {
        '3d_models': require("../content/3d_models.json"),
        'motion': require("../content/motion.json"),
        'graphics': require("../content/illustrations.json")
    };

    // -

    private itemContent(kind: ContentItemKind): Item | null {
        if (kind.type == 'folder') {
            const projects: ProjectTitleDto[] = JSON.parse(this.content[kind.kind]);
            return { 
                type: 'folder', 
                kind: kind, 
                projects: projects.map(it => 
                    new ProjectTitleItemModel(it, () => { 
                        const item: ProjectItemKind = {type: 'project', id: it.id, folder: kind.kind}
                        this.showItem(item); 
                        return item;
                    })
                ) 
            };
        } else if (kind.type == 'project') {
            const projects = JSON.parse(this.content[kind.folder]);
            if (projects.constructor.name != "Array") throw new Error('Expected array of projects'); 
            const project = (projects as Array<any>).find((e) => e.id == kind.id);
            return { type: 'project', id: project.id }
        } else {
            return null;
        }
    }

    private reduceState() {
        console.log("[content] currentState: ", toJS(this.currentState));
        console.log("[content] targetState: ", toJS(this.targetState));

        const current = this.currentState;
        const target = this.targetState;

        if (current.type == 'inactive' && target.type == 'idle') {
            this.currentState = { type: 'appearing', item: target.item };
        } else if (current.type == 'idle' && target.type == 'inactive') {
            this.currentState = { type: 'dismissing', item: current.item };
        } else if (current.type == 'idle' && target.type == 'idle') {
            this.currentState = { type: 'animating', from: current.item, to: target.item };
        }
    }

};