import * as React from "react";
import { ButtonColorKind, FrontButtonModel } from "../../stores/frontStore";

type FrontButtonState = {
    model: FrontButtonModel
    isHovering: boolean
    onClick: () => void
    onHoverChange: (a: boolean) => void
};

export const FrontButton = ({model, isHovering, onClick, onHoverChange}: FrontButtonState) => {
    const content = isHovering
        ? <FrontButtonHover text={model.text} color={model.color} lottieFile={model.lottieFile}/> 
        : <FrontButtonNormal color={model.color} text={model.text}/>;

    return (
        <div 
            className="main-button-square"
            onMouseOver={(e) => onHoverChange(true) }
            onMouseOut={(e) => onHoverChange(false) }
            onClick={(e) => {
                onHoverChange(false);
                onClick();
            }}
        >
            {content}
        </div>
    )
};

// -

type FrontButtonNormalState = {
    color: ButtonColorKind
    text: string
};

export const FrontButtonNormal = ({color, text}: FrontButtonNormalState) => (
    <a className={`flex-1 main-button-text ${color}-gradient ${color}-button-text`}>{text}</a>
);

// -

type FrontButtonHoverState = {
    text: string
    color: ButtonColorKind
    lottieFile: string
};

export const FrontButtonHover = ({text, color, lottieFile}: FrontButtonHoverState) => (
    <div className="flex-1 main-button-hover">
        <dotlottie-player
            autoplay
            loop
            src={require(`../../assets/front/${lottieFile}.lottie`)}
            speed={1}
            background="transparent"
        />  
        <div className={`main-button-text ${color}-button-text`}>{text}</div>
    </div>
);