import * as React from "react";
import TweenOne from "rc-tween-one";
import { IAnimObject } from "rc-tween-one/typings/AnimObject";

type NavigationSectionState = {
    offset: number;
    length: number;
    isFirst: boolean;
    isLast: boolean;
    isCompact: boolean;
    colorClass: string;
    onClick: () => void;
}

function sectionContainerStyle(offset: number, length: number, isCompact: boolean): React.CSSProperties {
    if (isCompact) {
        return {
            left: offset.toString()+'px',
            width: length.toString()+'px',
            height: '100%'     
        }
    } else {
        return {
            top: offset.toString()+'px',
            height: length.toString()+'px',
            width: '100%'
        }
    }
}

export const NavigationSection = ({offset, length, isFirst, isLast, isCompact, colorClass, onClick}: NavigationSectionState) => (
    <div className="navigation-section-container" style={sectionContainerStyle(offset, length, isCompact)}>
        <div className={"navigation-section" + (isFirst ? "-first" : "") + " " + colorClass} />
        <div className={"navigation-node" + (isLast ? "-last" : "") + " " + colorClass} onClick={(e) => onClick()} />
    </div>
);

// -

type AnimatingNavigationSectionState = {
    offset: number;
    length: number;
    isFirst: boolean;
    isAppearing: boolean;
    isCompact: boolean;
    colorClass: string;
    animation: IAnimObject;
}

function animatingContainerStyle(offset: number, length: number, isAppearing: boolean, isCompact: boolean): React.CSSProperties {
    if (isCompact) {
        return {
            left: offset.toString()+'px',
            width: isAppearing ? '0px' : length.toString()+'px',
            height: '100%'
        }     
    } else {
        return {
            top: offset.toString()+'px',
            height: isAppearing ? '0px' : length.toString()+'px',
            width: '100%'
        }
    }
}

export const AnimatingNavigationSection = ({offset, length, isFirst, isAppearing, isCompact, colorClass, animation}: AnimatingNavigationSectionState) => (
    <TweenOne 
        animation={animation}
        className="navigation-section-container"
        style={animatingContainerStyle(offset, length, isAppearing, isCompact)}
    >
        <div className={"navigation-section" + (isFirst ? "-first" : "") + " " + colorClass} />
        <div className={"navigation-node-last " + colorClass} />
    </TweenOne>
);