import * as React from "react";
import TweenOne from "rc-tween-one";
import { observer } from "mobx-react";
import { BaseComponent } from "../../stores/main";
import { CatScene } from "../catScene";
import { NavigationPanel } from "./panel";
import { LogoState } from "../../stores/navigationStore";
import { Size } from "../../stores/uiStore";
import { IAnimObject } from "rc-tween-one/typings/AnimObject";

export const Navigation = observer(
    class Navigation extends BaseComponent {

        render() {
            return (
                <React.Fragment>
                    <TweenOne
                        animation={{ opacity: 0 }}
                        reverse={this.store.appState != 'front'}
                    >
                        <NavigationPanel />
                    </TweenOne>
                    <NavigationLogo 
                        state={this.store.navigation.logoState}
                        isCompact={this.store.ui.isCompact}
                        screenSize={this.store.ui.screenSize}
                        onAnimationComplete={() => {
                            if (this.store.navigation.currentState.type == 'dismissing') {
                                this.store.navigation.handleDismissComplete();
                            } else if (this.store.navigation.currentState.type == 'appearing') {
                                this.store.handleNavigationAnimationEnd();
                            }
                        }}
                        onLogoClick={() => {
                            if (!this.store.navigation.isActive) { return; }
                            this.store.handleNavigationLogoTap();
                        }}
                    />
                    <TweenOne
                        id="scene-container"
                        animation={{ opacity: 0 }}
                        reverse={this.store.appState == 'front'}
                    >
                        <CatScene />
                    </TweenOne>
                </React.Fragment>
            );
        }
    }
);

type NavigationLogoState = {
    state: LogoState,
    isCompact: boolean,
    screenSize: Size,
    onAnimationComplete: () => void,
    onLogoClick: () => void
}

export const NavigationLogo = ({state, isCompact, screenSize, onAnimationComplete, onLogoClick}: NavigationLogoState) => {
    if (state == 'fullscreen') {
        return <div id="navigation-logo" style={fullScreenStyle(screenSize)} />
    } else if (state == 'collapsing') {
        return <TweenOne 
            id="navigation-logo" 
            style={fullScreenStyle(screenSize)}
            animation={cornerAnimation(isCompact, onAnimationComplete)}
        />
    } else if (state == 'expanding') {
        return <TweenOne 
            id="navigation-logo" 
            style={cornerStyle(isCompact)} 
            animation={fullScrenAnimation(screenSize, onAnimationComplete)}
        />
    } else /* (state == 'corner') */ {
         return <div id="navigation-logo" style={cornerStyle(isCompact)} onClick={onLogoClick} />
    }
};

function fullScreenStyle(screenSize: Size): React.CSSProperties {
    return { width: screenSize.width, height: screenSize.height, top: 0, left: 0 };
}

function fullScrenAnimation(screenSize: Size, onComplete: () => void): IAnimObject {
    return { width: screenSize.width, height: screenSize.height, top: 0, left: 0, onComplete: onComplete };
}

function cornerStyle(isCompact: boolean): React.CSSProperties {
    return {
        width: isCompact ? 64 : 80,
        height: isCompact ? 80 : 100,
        top: isCompact ? 14 : 20,
        left: isCompact ? 12 : 16
    }
}

function cornerAnimation(isCompact: boolean, onComplete: () => void): IAnimObject {
    return {
        width: isCompact ? 64 : 80,
        height: isCompact ? 80 : 100,
        top: isCompact ? 14 : 20,
        left: isCompact ? 12 : 16,
        onComplete: onComplete
    }
}