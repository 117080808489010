import * as React from "react";
import TweenOne from "rc-tween-one";
import { observer } from "mobx-react";
import { BaseComponent } from "../../stores/main";
import { FolderItem as FolderItemView, TransitionFolderItem } from "./folderItem";
import { ProjectItem as ProjectItemView } from "./projectItem";
import { ContentState, FolderItem, Item, ProjectItem } from "../../stores/contentStore";
import { projectProvider } from "./projectProvider";

export const Content = observer(
    class Content extends BaseComponent {

        render() {
            const state = this.store.content.currentState
            const items = this.getItems(state);

            return <TweenOne
                id="content-grid" 
                className="uk-width-1-1 uk-height-1-1 uk-grid-collapse" 
                uk-grid="true"
                animation={{ 
                    opacity: 1,
                    // -
                    onComplete: (e) => {
                        if (['appearing', 'dismissing'].includes(this.store.content.currentState.type)) {
                            this.store.content.handleTransitionComplete();
                        }
                    }
                }}
                reverse={this.store.content.isTransitionReversed}
            >
                {items}
                <TweenOne
                    animation={{ 
                        onComplete: (e) => {
                            if (this.store.content.currentState.type == 'animating') {
                                this.store.content.handleTransitionComplete();
                            }
                        }
                    }} 
                    reverse={false}
                    paused={this.store.content.currentState.type != 'animating'}
                    moment={ (this.store.content.currentState.type != 'animating') ? 0 : undefined }
                />
            </TweenOne>
        }

        private getItems(state: ContentState): any {
            if (state.type == 'appearing') {
                return this.getItem(state.item);
            } else if (state.type == 'idle') {
                return this.getItem(state.item);
            } else if (state.type == 'dismissing') {
                return this.getItem(state.item);
            } else if (state.type == 'animating') {
                return this.getItem(state.to);
            } else {
                return <div />;
            }
        }

        private getItem(item: Item): any {
            if (item.type == 'folder') {
                return this.getFolderItems(item);
            } else if (item.type == 'project') {
                return this.getProjectItem(item);
            }
        }

        private getFolderItems(folder: FolderItem): any {
            if (this.store.content.isAnimating) {
                return folder.projects.map(it => 
                    <div className={this.store.ui.isCompact ? "uk-width-1-1" : "uk-width-1-2"} key={it.id}>
                        <TransitionFolderItem
                            image={this.store.assetsProvider.getFolderAsset(it.id)}
                            title={it.title}
                            isHovering={it.hoverState}
                            onHoverChange={(value) => it.changeHoverState(value)}
                            onClick={() => {}}
                        />
                    </div>
                )
            } else {
                return folder.projects.map(it => 
                    <div className={this.store.ui.isCompact ? "uk-width-1-1" : "uk-width-1-2"} key={it.id}>
                        <FolderItemView
                            image={this.store.assetsProvider.getFolderAsset(it.id)}
                            title={it.title}
                            isHovering={it.hoverState}
                            onHoverChange={(value) => it.changeHoverState(value)}
                            onClick={() => {
                                if (!projectProvider.getProjectContent(it.id, this.store.assetsProvider)) { return; }
                                const item = it.onClick();
                                this.store.navigation.routeForward(item);
                            }}
                        />
                    </div>
                )
            }
        }

        private getProjectItem(project: ProjectItem): any {
            return <ProjectItemView itemId={project.id}/>;
        }

    }
);