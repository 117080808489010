import { action, autorun, computed, makeObservable, observable, toJS } from "mobx";
import { ContentItemKind, FolderKind } from "./contentStore";
import isEqual from "lodash.isequal";

// -

type Inactive = { type: 'inactive' };
type Idle = { type: 'idle', depth: number };
type StableState = Inactive | Idle;

type Appearing = { type: 'appearing' };
type Dismissing = { type: 'dismissing' };
type Animating = { type: 'animating', from: number, to: number };
type TransitionState = Appearing | Dismissing | Animating;

type NavigationState = StableState | TransitionState;

export type LogoState = 'fullscreen' | 'collapsing' | 'expanding' | 'corner';
export type ColorKind = 'red' | 'green' | 'blue';

// -

export class NavigationStore {
  
    screensStack: Array<ContentItemKind> = [];
    colorKind: ColorKind | null = null;
    currentState: NavigationState = { type: 'inactive' };

    constructor() {
        makeObservable<NavigationStore, 
            'pendingScreen' | 
            'targetState' | 
            'resetColorKind' | 
            'reduceState'>(this, {
                screensStack: observable,
                colorKind: observable,
                currentState: observable,
                pendingScreen: observable,
                targetState: observable,
                isAppeared: computed,
                isAnimating: computed,
                isGoingUpToRoot: computed,
                isActive: computed,
                logoAnimationReversed: computed,
                depth: computed,
                setPendingScreen: action,
                resetColorKind: action,
                routeBackToDepth: action,
                routeForward: action,
                routeToPendingScreen: action,
                routeToRoot: action,
                routeToInactive: action,
                handleAnimationComplete: action,
                handleAppearComplete: action,
                handleDismissComplete: action,
                reduceState: action
            });
       
        autorun(() => { 
            if (!isEqual(this.currentState, this.targetState)) { 
                setTimeout(() => this.reduceState(), 100); 
            } 
        });

        autorun(() => { 
            if (this.currentState.type == 'dismissing') this.resetColorKind();
        });
    }

    // -

    get isAppeared(): boolean {
        return ['idle', 'animating'].includes(this.currentState.type);
    }

    get isAnimating(): boolean {
        return ['animating', 'appearing', 'dismissing'].includes(this.currentState.type);
    }

    get isGoingUpToRoot(): boolean {
        return this.currentState.type == 'animating' && this.currentState.from == 1 && this.currentState.to == 0;
    }

    get isActive(): boolean {
        return this.currentState.type != 'inactive';
    }

    get logoAnimationReversed(): boolean {
        return ['inactive', 'dismissing'].includes(this.currentState.type);
    }

    get logoState(): LogoState {
        switch (this.currentState.type) {
        case 'inactive': 
            return 'fullscreen';
        case 'appearing': 
            return 'collapsing';
        case 'dismissing': 
            return 'expanding';
        case 'idle':
        case 'animating':
            return 'corner';
        }
    }

    get depth(): number {
        const state = this.currentState
        if (state.type == 'idle') { return state.depth; }
        if (state.type == 'animating') { return state.from; }
        return 0;
    }

    // -

    routeBackToDepth(value: number) {
        if (value > this.depth) { throw new Error("Can't to route deeper"); }
        this.screensStack = this.screensStack.slice(0, value);
        this.targetState = { type: 'idle', depth: value };
    }

    routeForward(itemId: ContentItemKind) {
        if (this.currentState.type != 'idle') { throw new Error("Can route forward only from idle state"); }
        this.screensStack.push(itemId);
        this.targetState = { type: 'idle', depth: this.currentState.depth + 1 };

        if (itemId.type == 'folder') {
            this.colorKind = this.colorClass(itemId.kind);
        }
    }

    routeToPendingScreen() {
        if (!this.pendingScreen) { throw new Error("Pending screen is not saved") }
        this.routeForward(this.pendingScreen);
        this.pendingScreen = null;
    }

    routeToRoot() {
        this.screensStack = [];
        this.targetState = { type: 'idle', depth: 0 };
    }

    routeToInactive() {
        this.screensStack = [];
        this.targetState = { type: 'inactive' };
    }

    setPendingScreen(screen: ContentItemKind) {
        if (this.pendingScreen) { throw new Error("Can't hold multiple pending screens") }
        this.pendingScreen = screen;
    }

    // -

    handleAnimationComplete() {
        if (this.currentState.type != 'animating') { throw new Error("Animating state expected") }
        this.currentState = { type: 'idle', depth: this.currentState.to };
    }

    handleAppearComplete() {
        if (this.currentState.type != 'appearing') { throw new Error("Appearing state expected") }
        this.currentState = { type: 'idle', depth: 0 };
    }

    handleDismissComplete() {
        if (this.currentState.type != 'dismissing') { throw new Error("Dismissing state expected") }
        this.currentState = { type: 'inactive' };
    }

    // -

    private pendingScreen: ContentItemKind | null = null;
    private targetState: StableState = { type: 'inactive' };

    // -

    private colorClass(item: FolderKind): ColorKind {
        switch (item) {
        case '3d_models': return 'red'
        case 'motion': return 'green'
        case 'graphics': return 'blue'
        }
    }

    private resetColorKind() {
        this.colorKind = null;
    }

    private reduceState() {
        console.log("[navigation] currentState: ", toJS(this.currentState));
        console.log("[navigation] targetState: ", toJS(this.targetState));

        const current = this.currentState;
        const target = this.targetState;

        if (current.type == 'inactive' && target.type == 'idle') {
            this.currentState = { type: 'appearing' };
        } else if (current.type == 'idle' && target.type == 'inactive') {
            if (current.depth > 0) {
                this.currentState = { type: 'animating', from: current.depth, to: current.depth - 1 }
            } else {
                this.currentState = { type: 'dismissing' };
            }
        } else if (current.type == 'idle' && target.type == 'idle') {
            if (current.depth < target.depth) {
                this.currentState = { type: 'animating', from: current.depth, to: current.depth + 1 };
            } else if (current.depth > target.depth) {
                this.currentState = { type: 'animating', from: current.depth, to: current.depth - 1 };
            }
        }
    }

};
