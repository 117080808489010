import * as React from "react";
import { AssetsProvider } from "../../stores/assetsProvider";

type ContentParts = {
    id: string
    header: JSX.Element
    text: JSX.Element
    image: JSX.Element
    footer: JSX.Element
    tools: JSX.Element
};

export const Content = ({id, header, text, image, footer, tools}: ContentParts) => {
    return (
        <div className="project-item-root" id={id}>
            <div className="project-block-header">{header}</div>
            <div className="project-block-text">{text}</div>
            <div className="project-block-image">{image}</div>
            <div className="project-block-footer"><div className="project-footer-content">{footer}</div></div>
            <div className="project-block-tools">{tools}</div>
        </div>
    );
};

type DotLottieId = {
    asset: string
    provider: AssetsProvider
    id?: string
}

export const DotLottie = ({asset, provider, id}: DotLottieId) => {
    return (
        <dotlottie-player
            autoplay
            loop
            src={provider.getProjectAsset(asset)}
            speed={1}
            id={id}
        />  
    );
}

export class ProjectProvider {

    getProjectContent(id: string, assetsProvider: AssetsProvider): JSX.Element {
        switch (id) {
        // 3D
        case 'pink_face': return <Content  
            id="project-pink-face"
            header={(
                <div className="project-subgrid">
                    <img src={assetsProvider.getProjectAsset('pink_face_happy_1')}/>
                    <img src={assetsProvider.getProjectAsset('pink_face_neutral_1')}/>
                </div>
            )}
            text={(
                <div className="project-text">
                    <p>In-flow is an application for tracking your emotional state. The user selects an emoji and leaves a note of what he feels at the moment, and all his data is recorded in the form of graphs. Thus, the user can track how his mental state is changing and what has affected him.</p>
                    <p>I made a set of 6 emoji for Apple Watch: 2 gradations for each of the states - joy, neutral state, sadness.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid">
                    <img src={assetsProvider.getProjectAsset('pink_face_sad_1')}/>
                    <div/>
                    <div/>
                    <img src={assetsProvider.getProjectAsset('pink_face_happy_2')}/>
                    <img src={assetsProvider.getProjectAsset('pink_face_neutral_2')}/>
                    <img src={assetsProvider.getProjectAsset('pink_face_sad_2')}/>
                </div>
            )}
            footer={(<div/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, ZBrush, Keyshot, Adobe Photoshop
                </div>
            )}
        />;
        case 'drinkins': return <Content  
            id="project-drinkins"
            header={(<img src={assetsProvider.getProjectAsset('drinkins_1')}/>)}
            text={(
                <div className="project-text">
                    <p>I made two mascots (comic duo) for the App in the Air brand: Mr. Drinkins and Mr. Soberson. One of them is short and gray with dark wings, and the second is tall dark gray with the light wings. These are two simple and cute airplanes that help the user to see and feel the assistance inside the application. They also have been used for many marketing campaigns.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid">
                    <img className="project-subgrid-2x2" src={assetsProvider.getProjectAsset('drinkins_2')}/>
                    <img src={assetsProvider.getProjectAsset('drinkins_5')}/>
                    <img src={assetsProvider.getProjectAsset('drinkins_6')}/>
                </div>
            )}
            footer={(
                <div className="project-subgrid">
                    <img src={assetsProvider.getProjectAsset('drinkins_3')}/>
                    <img src={assetsProvider.getProjectAsset('drinkins_4')}/>
                </div>
            )}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    ZBrush, KeyShot, Adobe Photoshop
                </div>
            )}
        />;
        case 'ar_achivements': return <Content  
            id="project-ar-achivements"
            header={(<img src={assetsProvider.getProjectAsset('ar_achivements_1')}/>)}
            text={(
                <div className="project-text">
                    <p>Set of AR-models was made as an addition to the achievements which the user receives for visiting a specific country. I made the models as realistic as possible so they become a kind of souvenir for travelers and at any time you can open the application, put the model on any surface and remember your impressions of the trip.</p>
                    <p>In total, 16 models were created for the most visited countries in the world.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('ar_achivements_2')}/>)}
            footer={(
                <div className="project-subgrid">
                    <img src={assetsProvider.getProjectAsset('ar_achivements_3')}/>
                    <img src={assetsProvider.getProjectAsset('ar_achivements_4')}/>
                </div>
            )}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, 3ds Max, Blender, Zbrush, KeyShot
                </div>
            )}
        />;
        case 'plane_seats': return <Content  
            id="project-plane-seats"
            header={(<img src={assetsProvider.getProjectAsset('plane_seats_1')}/>)}
            text={(
                <div className="project-text">
                    <p>I made many different 3D models of aircraft cabins for the App in the Air application, so the user can see the aircraft from the inside in augmented reality. When a passenger buys a ticket, he has an option to look around inside the aircraft, to understand where his seat is and how much the back of the seat reclines. I completely recreated the Alaska Airlines fleet and also made a 3d-model of the Polaris VIP seats.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid-collapsing">
                    <img className="project-subgrid-2x2" src={assetsProvider.getProjectAsset('plane_seats_2')}/>
                </div>
            )}
            footer={(<img src={assetsProvider.getProjectAsset('plane_seats_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, KeyShot, Blender
                </div>
            )}
        />;
        case 'wood_lamp': return <Content  
            id="project-wood-lamp"
            header={(<img src={assetsProvider.getProjectAsset('wood_lamp_1')}/>)}
            text={(
                <div className="project-text">
                    <p>Development of a wooden pendant lamp design for Yolky Woodwork. The peculiarity of this lamp is that it consists entirely of flat plywood plates cut by a laser machine. I made an explosion scheme, built an drawing and created a visualization of this design.</p>
                </div>
            )}
            image={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('wood_lamp_2')} type="video/mp4" />
                </video> 
            )}
            footer={(<img src={assetsProvider.getProjectAsset('wood_lamp_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, KeyShot
                </div>
            )}
        />;
        case 'yota_ring': return <Content  
            id="project-yota-ring"
            header={(<img src={assetsProvider.getProjectAsset('yota_ring_1')}/>)}
            text={(
                <div className="project-text">
                    <p>Bluetooth keychain project from Yota Devices. This device works as a Bluetooth tag with a battery. You can always track the keychain with your phone, so if you are afraid of losing something, then attach this keychain to the item. It also works as a SOS-button, and sends a signal to the smartphone, the function is activated when you press the button on the back.
                    <br/>I developed the design, completely built the housing structure, and prepared drawings and material descriptions for production.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid">
                    <img className="project-subgrid-2x2" src={assetsProvider.getProjectAsset('yota_ring_2')}/>
                    <img className="project-subgrid-1x2" src={assetsProvider.getProjectAsset('yota_ring_4')}/>
                </div>
            )}
            footer={(<img src={assetsProvider.getProjectAsset('yota_ring_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, KeyShot, Blender
                </div>
            )}
        />;
        case 'reciever': return <Content  
            id="project-reciever"
            header={(<img src={assetsProvider.getProjectAsset('reciever_1')}/>)}
            text={(
                <div className="project-text">
                    <p>I helped with the product line for the Etalon Sound company, a manufacturer of premium equipment for music lovers. I made the design of several digital-to-analog converters, consoles and sound amplifiers. Also I prepared differents documents for production and drawings.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid-collapsing">
                    <img className="project-subgrid-2x2" src={assetsProvider.getProjectAsset('reciever_2')}/>
                </div>
            )}
            footer={(<img src={assetsProvider.getProjectAsset('reciever_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Rhinoceros, KeyShot
                </div>
            )}
        />;
        case 'ar_masks': return <Content  
            id="project-ar-masks"
            header={(<img src={assetsProvider.getProjectAsset('ar_masks_1')}/>)}
            text={(
                <div className="project-text">
                    <p>One day App in the Air marketing team decided to implement AR masks in their social networks to promote the brand. As a result, I learned how to work with the Spark AR program :). I made a lot of masks with different mechanics: lottery mask, match mask for two people, masks with 3D-models, mask for choosing an option with a nod of the head, mask with background replacement and many others.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid-collapsing">
                    <img className="project-subgrid-2x2" src={assetsProvider.getProjectAsset('ar_masks_2')}/>
                </div>
            )}
            footer={(<img src={assetsProvider.getProjectAsset('ar_masks_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Spark AR, Cinema 4D, ZBrush, Figma, Adobe Illustrator
                </div>
            )}
        />;
        // Motion
        case 'sleepy_animations': return <Content  
            id="project-sleepy-animations"
            header={(
                <div id="sleepy-animations-cell-1">
                    <img src={assetsProvider.getProjectAsset('sleepy_animations_1_fill')}/>
                    <DotLottie asset='sleepy_animations_1' provider={assetsProvider} id='sleepy-animations-1' />
                </div>
            )}
            text={(
                <div className="project-text">
                    <p><a href="https://sleepyrobot.ru/">Sleepy Robot</a> is a service that helps improve your sleep. It is available as a bot and as a standalone app. For the Sleepy app, I designed many different videos and Lottie-animations, from simple particle flickering, moving backgrounds to mascot interaction animations.</p>
                </div>
            )}
            image={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('sleepy_animations_3')} type="video/mp4" />
                </video> 
            )}
            footer={(<DotLottie asset='sleepy_animations_2' provider={assetsProvider} />)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Media Encoder
                </div>
            )}
        />;
        case 'aita_marketing': return <Content  
            id="project-aita-marketing"
            header={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('aita_marketing_1')} type="video/mp4" />
                </video> 
            )}
            text={(
                <div className="project-text">
                    <p>For 4 years of work in App in the Air, I needed to make a lot of videos for various purposes: advertising in social networks, announcements of new events, review of the use of new features, congratulations on the holidays, collages from our users, a video review of the application for the App&nbsp;Store and Google&nbsp;Play etc.</p>
                    <p>A couple of examples can be seen on this page, and I’ll highlight one more video of the event on <a href="https://youtu.be/jvn3HO80T6Q">YouTube</a>.</p>
                </div>
            )}
            image={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('aita_marketing_2')} type="video/mp4" />
                </video> 
            )}
            footer={(<div/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Media Encoder
                </div>
            )}
        />;
        case 'aita_stickers': return <Content  
            id="project-aita-stickers"
            header={(
                <div className="project-subgrid">
                    <div/>
                    <DotLottie asset="aita_stickers_evil" provider={assetsProvider} />
                </div>
            )}
            text={(
                <div className="project-text">
                    <p>A lot of animated stickers were used by App in the Air in social networks (Facebook, Instagram, Twitter) and instant messengers (Telegram, WhatsApp, Viber). I decided that to promote the brand, it is better to use a neutral hero in animations, so a separate aviation-themed sticker pack was created with Mr. Drinkins and Soberson.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid">
                    <DotLottie asset="aita_stickers_travelator" provider={assetsProvider} />
                    <div/>
                    <div/>
                    <DotLottie asset="aita_stickers_beach" provider={assetsProvider} />
                    <DotLottie asset="aita_stickers_drinkins" provider={assetsProvider} />
                    <DotLottie asset="aita_stickers_passport" provider={assetsProvider} />
                </div>
            )}
            footer={(
                <div className="project-subgrid">
                    <DotLottie asset="aita_stickers_voting" provider={assetsProvider} />
                    <DotLottie asset="aita_stickers_packing" provider={assetsProvider} />
                </div>
            )}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Media Encoder
                </div>
            )}
        />;
        case 'aita_placeholders': return <Content  
            id="project-aita-placeholders"
            header={(
                <div className="project-subgrid">
                    <DotLottie asset="aita_placeholders_2" provider={assetsProvider} id='aita-placeholders-2' />
                    <div/>
                </div>
            )}
            text={(
                <div className="project-text">
                    <p>Inside the app in the air there is a huge functionality that the user will learn about step by step. In order to make the immersion smoother, it has auxiliary animations in the angular style of the application icons.</p>
                    <p>During my work, I made dozens of different animated illustrations for bottom shields, success screens, placeholders, etc.</p>
                </div>
            )}
            image={(
                <div className="project-subgrid-collapsing">
                    <div className="project-subgrid-2x2">
                        <div className="project-subgrid">
                            <div/>
                            <DotLottie asset="aita_placeholders_3" provider={assetsProvider} id='aita-placeholders-3' />
                            <DotLottie asset="aita_placeholders_4" provider={assetsProvider} id='aita-placeholders-4' />
                            <div/>
                            <div/>
                            <DotLottie asset="aita_placeholders_5" provider={assetsProvider} id='aita-placeholders-5' />
                        </div>
                    </div>
                </div>
            )}
            footer={(
                <div>
                    <div className="project-subgrid">
                        <img id='aita-placeholders-1-fill' src={assetsProvider.getProjectAsset('aita_placeholders_1_fill')} />
                        <DotLottie asset="aita_placeholders_1" provider={assetsProvider} id='aita-placeholders-1' />
                    </div>
                </div>
            )}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Media Encoder
                </div>
            )}
        />;
        case 'yota_animations': return <Content  
            id="project-yota-animations"
            header={(<img src={assetsProvider.getProjectAsset('yota_animations_1')}/>)}
            text={(
                <div className="project-text">
                    <p>Back in 2017, Yota Devices was preparing to release Yotaphone 3 in China. Yotaphone is a phone with two screens, on the front side there is a regular OLED display, and on the back side there is an E-ink display for comfortable reading books and articles.</p>
                    <p>I designed a boot animation of the appearing logo when user turn on the phone, on this page you can see several options. All animations are limited to 8 fps due to E-ink technology.</p>
                </div>
            )}
            image={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('yota_animations_2')} type="video/mp4" />
                </video> 
            )}
            footer={(<div/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Media Encoder
                </div>
            )}
        />;
        case 'life_animations': return <Content  
            id="project-life-animations"
            header={(<img src={assetsProvider.getProjectAsset('life_animations_1')}/>)}
            text={(
                <div className="project-text">
                    <p>LIFE is a project of an entertainment system on board the aircraft. Unlike most entertainment systems, the LIFE system is not mounted in aircraft seats, the user simply needs to connect via his smartphone/tablet/computer to Wi-Fi on board the aircraft to access media content.</p>
                    <p>For the LIFE project, I initially developed the housing of a device that distributes media content, but then I started making commercials explaining how the system works and what services it provides. As part of this project, I also developed a mascot robot that sometimes appears in the videos.</p>
                </div>
            )}
            image={(
                <video autoPlay loop muted>
                    <source src={assetsProvider.getProjectAsset('life_animations_2')} type="video/mp4" />
                </video> 
            )}
            footer={(<div/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe After Effects, Adobe Illustrator, Adobe Media Encoder
                </div>
            )}
        />;
        // Illustrations
        case 'robots': return <Content  
            id="project-robots"
            header={(<img src={assetsProvider.getProjectAsset('robots_1')}/>)}
            text={(
                <div className="project-text">
                    <p>My first freelance job in 2016 related to the creation of several headers for a site dedicated to the game RF&nbsp;Online. This was my first experience of creating detailed raster paintings. I made the background for them separately in a 3D&nbsp;editor, and then adapted it to the character in the foreground.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('robots_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('robots_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Photoshop, Rhinoceros, Keyshot
                </div>
            )}
        />;
        case 'telegram_stickers': return <Content  
            id="project-stickers"
            header={(<img src={assetsProvider.getProjectAsset('telegram_stickers_1')}/>)}
            text={(
                <div className="project-text">
                    <p>We have developed a tradition during my work at App in the Air: for every birthday a colleague receives his personal sticker from me in the corporate sticker pack. In 4 years of work, I have drawn more than 100 sticker portraits in this way.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('telegram_stickers_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('telegram_stickers_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Photoshop
                </div>
            )}
        />;
        case 'life_game_backgrounds': return <Content  
            id="project-life-games"
            header={(<img src={assetsProvider.getProjectAsset('life_game_backgrounds_1')}/>)}
            text={(
                <div className="project-text">
                    <p>LIFE is a project of an entertainment system on board the aircraft. In addition to movies, series and articles, there are also games inside the LIFE system. I managed to take part in the development of a couple of on-board games, for which I drew endless backgrounds and developed characters based on the LIFE system robot mascot.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('life_game_backgrounds_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('life_game_backgrounds_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Illustrator, Adobe Photoshop
                </div>
            )}
        />;
        case 'aita_banners': return <Content  
            id="project-aita-banners"
            header={(<img src={assetsProvider.getProjectAsset('aita_banners_1')}/>)}
            text={(
                <div className="project-text">
                    <p>During work for App in the Air company, I often made banners for publications on social networks, advertising on Google&nbsp;Play, Samsung&nbsp;Store, App&nbsp;Store, posters and design for various events with users, engaged in merchandise, etc.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('aita_banners_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('aita_banners_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Illustrator, Adobe Photoshop, Figma
                </div>
            )}
        />;
        case 'raster_art': return <Content
            id="project-raster-art"
            header={(<img src={assetsProvider.getProjectAsset('raster_art_1')}/>)}
            text={(
                <div className="project-text">
                    <p>I often draw in my free time. At first, it was more traditional drawings on paper, but then I began to get more and more into digital graphics. I am interested in different styles and directions and sometimes I try to mix them, not always successfully, but people learn from mistakes :)</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('raster_art_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('raster_art_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Illustrator, Adobe Photoshop, Figma, Procreate, Autodesk Sketchbook, Traditional Art
                </div>
            )}
        />;
        case 'aita_achievements': return <Content
            id="project-achievements"
            header={(<img src={assetsProvider.getProjectAsset('achievements_1')}/>)}
            text={(
                <div className="project-text">
                    <p>When I came to App in the Air company (an app for travelers) in 2018, the main color of the app was blue-green and all the achievements were the same: the silhouette of the main attraction in a circle. In 2020, the application has undergone a redesign, and along with it, the achievements inside the application have changed as well. More than 200 achievements I made as a stamps in the passport.</p>
                </div>
            )}
            image={(<img src={assetsProvider.getProjectAsset('achievements_2')}/>)}
            footer={(<img src={assetsProvider.getProjectAsset('achievements_3')}/>)}
            tools={(
                <div className="project-text">
                    <b>Instruments:</b><br/>
                    Adobe Illustrator
                </div>
            )}
        />;
        default:
            throw new Error(`Project page '${id}' is not implemented.`);
        }
    }

}

export const projectProvider = new ProjectProvider();