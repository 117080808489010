import { makeAutoObservable } from "mobx";


export class LoaderStore {

    isPlaying: Boolean = false;
    isAssetsLoaded: Boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    handleAssetsLoaded() {
        this.isAssetsLoaded = true;
    }

    handleLoaderStart() {
        console.log("handleLoaderStart");
        this.isPlaying = true;

        const loader = document.getElementById('loader-lottie');
        if (!loader) throw new Error('Failed to find the loader component');
        loader.addEventListener('loop', () => this.handleLoaderEnd());
    }

    handleLoaderEnd() {
        console.log("handleLoaderEnd");
        if (this.isAssetsLoaded) {
            this.isPlaying = false;
        }
    }

}