import * as React from "react";
import { observer } from "mobx-react";
import { BaseComponent } from "../../stores/main";
import { AnimatingNavigationSection, NavigationSection } from "./section";
import { ColorKind } from "../../stores/navigationStore";

type SectionModel = {
    depth: number,
    offset: number,
    length: number,
    animationState: 'no' | 'in' | 'out',
    colorClass: string,
    isFirst: boolean,
    isLast: boolean
}

export const NavigationPanel = observer(
    class NavigationPanel extends BaseComponent {

        render() {
            var sections: Array<SectionModel> = [];
            var nextSectionOffset: number;
            var nextSectionLength: number;
            
            if (this.store.ui.isCompact) {
                nextSectionOffset = 80;
                nextSectionLength = (this.store.ui.screenSize.width - 80) / 3;
            } else {
                nextSectionOffset = 100;
                nextSectionLength = (this.store.ui.screenSize.height - 100) / 3;       
            }

            const state = this.store.navigation.currentState;
            const colorClass = this.colorClass(this.store.navigation.colorKind);
            var depth: number = 0;

            if (state.type == 'idle') {
                depth = state.depth;
            } else if (state.type == 'animating') {
                depth =  Math.min(state.from, state.to);
            }

            for (let i = 0; i < depth; i++) {
                sections.push({
                    depth: i + 1,
                    offset: nextSectionOffset,
                    length: nextSectionLength,
                    animationState: 'no',
                    colorClass: colorClass,
                    isLast: i == depth - 1,
                    isFirst: i == 0
                });
                nextSectionOffset += nextSectionLength;
            }

            if (state.type == 'animating') {
                if (depth > 0) { 
                    sections[depth - 1].isLast = false; 
                }
                sections.push({
                    depth: depth + 1,
                    offset: nextSectionOffset,
                    length: nextSectionLength,
                    animationState: state.from < state.to ? 'in' : 'out',
                    colorClass: colorClass,
                    isLast: true,
                    isFirst: depth == 0
                });
            }

            return (
                <div id="navigation-panel">
                    {sections.map((model) => this.makeSection(model))}
                </div>
            );
        }

        private colorClass = (item: ColorKind | null) => {
            if (!item) return '';
            return 'navigation-' + item;
        }

        private makeSection = (model: SectionModel) => {
            if (model.animationState == 'no') {
                return <NavigationSection 
                    key={model.depth.toString()} 
                    offset={model.offset} 
                    length={model.length} 
                    isFirst={model.isFirst}
                    isLast={model.isLast} 
                    isCompact={this.store.ui.isCompact}
                    colorClass={model.colorClass}
                    onClick={() => { 
                        if (this.store.navigation.depth == model.depth) { return; }
                        this.store.navigation.routeBackToDepth(model.depth);

                        const stack = this.store.navigation.screensStack;
                        const topScreen = stack[stack.length - 1];
                        if (!topScreen) { return; }
                        this.store.content.showItem(topScreen);
                    }}
                />
            } else {
                return <AnimatingNavigationSection
                    key={model.depth.toString()}
                    offset={model.offset}
                    length={model.length}
                    isFirst={model.isFirst}
                    isAppearing={model.animationState == 'in'}
                    isCompact={this.store.ui.isCompact}
                    colorClass={model.colorClass}
                    animation={
                        this.store.ui.isCompact 
                            ? {
                                width: model.animationState == 'in' ? model.length : 0,
                                onComplete: (e) => this.store.navigation.handleAnimationComplete()
                            } : {
                                height: model.animationState == 'in' ? model.length : 0,
                                onComplete: (e) => this.store.navigation.handleAnimationComplete()     
                            }
                    }
                />
            }
        }

    }
);