import * as React from "react";
import { observer } from "mobx-react";
import { BaseComponent } from "../../stores/main";
import { FrontButton } from "./button";

export const Front = observer(
    class Front extends BaseComponent {
        render() {
            return (
                <div id="front-scene">
                    {
                        this.store.front.buttons
                            .map(it =>
                                <div key={it.kind} className="main-button-cell">
                                    <FrontButton
                                        model={it} 
                                        isHovering={it.isHovered} 
                                        onHoverChange={(value) => it.changeHoverState(value)}
                                        onClick={() => this.store.handleFrontButtonTap(it.kind)}
                                    />
                                </div>
                            )
                    }         
                </div>
            );
        }
    }
);