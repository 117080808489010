import * as React from "react";
import { useStore } from "../../stores/main";
import { projectProvider } from "./projectProvider";

type ProjectItemState = {
    itemId: string
};

export const ProjectItem = ({itemId}: ProjectItemState) => {
    return (<div>{projectProvider.getProjectContent(itemId, useStore().assetsProvider)}</div>);
};