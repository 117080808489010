import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { FolderKind } from "./contentStore";

export type ButtonKind = FolderKind;
export type ButtonColorKind = 'red' | 'green' | 'blue'

export class FrontButtonModel {
    kind: ButtonKind;
    color: ButtonColorKind;
    lottieFile: string;
    text: string;
    
    hoverState: boolean = false;
    hoverLocked: boolean = false;

    get isHovered(): boolean {
        return this.hoverState && !this.hoverLocked;
    }

    changeHoverState(value: boolean) { this.hoverState = value };
    changeHoverLock(value: boolean) { this.hoverLocked = value };

    constructor(kind: ButtonKind) {
        switch (kind) {
            case '3d_models':
                this.color = 'red';
                this.lottieFile = 'red_button';
                this.text = '3D\nmodels';
                break;
            case 'motion':
                this.color = 'green';
                this.lottieFile = 'green_button';
                this.text = 'Motion\ngraphics';
                break;
            case 'graphics':
                this.color = 'blue';
                this.lottieFile = 'blue_button';
                this.text = 'Illustrations';
                break;
        }
        this.kind = kind;

        makeObservable(this, {
            hoverState: observable,
            hoverLocked: observable,
            isHovered: computed,
            changeHoverState: action,
            changeHoverLock: action
        });
    }
}

export class FrontStore {
    buttons = [
        new FrontButtonModel('3d_models'),
        new FrontButtonModel('motion'),
        new FrontButtonModel('graphics')
    ]

    hoverLocks: number = 0;

    constructor() {
        makeObservable(this, {
            buttons: observable,
            hoverLocks: observable,
            lockButtonsHover: action,
            unlockButtonsHover: action
        });
    }

    lockButtonsHover() {
        if (this.hoverLocks == 0) {
            this.buttons.forEach((button) => {
                button.changeHoverLock(true);
                button.changeHoverState(false);
            });
        }
        this.hoverLocks += 1;
    }

    unlockButtonsHover() {
        this.hoverLocks = Math.max(0, this.hoverLocks - 1);
        if (this.hoverLocks == 0) {
            this.buttons.forEach((button) => {
                button.changeHoverLock(false);
            });
        }
    }
};